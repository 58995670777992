import React from 'react';

import { ProductItem } from 'src/components/CategoryListingPage/ProductListItem';
import { ProductListConfigurable } from 'src/components/ConfigurableOptions/components';

import classes from './ProductList.scss';
import { ProductListItem } from './utils/productListItem';

export type ProductListItemsProps = {
    gtmSelectItem(index: number): void;
    loading: boolean;
    openProductInNewTab?: boolean;
    products: ProductListItem[];
};

export function ProductListItems(props: ProductListItemsProps) {
    const { loading, gtmSelectItem, products } = props;
    return (
        <ul className={classes.list}>
            {products.map((product, index) => {
                return (
                    <li
                        key={product.id}
                        className={classes.item}
                        data-test-id="ProductItem"
                        data-item-loading={String(loading)}
                    >
                        <ProductItem
                            product={product}
                            gtmSelectItem={gtmSelectItem}
                            index={index}
                            openLinkInNewTab={props.openProductInNewTab}
                            configurableOptions={
                                product.configurableOptions && (
                                    <ProductListConfigurable configurableOptions={product.configurableOptions} />
                                )
                            }
                        />
                    </li>
                );
            })}
        </ul>
    );
}
