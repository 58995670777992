import React from 'react';

import { LazyImg } from 'src/hooks/useLazyLoad';
import { ProductType } from 'src/types/global-types';

import { ProductListItem } from './utils/productListItem';

export type ProductListItemImageProps = {
    product: ProductListItem;
};

export function ProductListItemImage(props: ProductListItemImageProps) {
    const { product } = props;

    const firstConfigurableVariant = product.configurableOptions?.variants?.[0];

    switch (product.type) {
        case ProductType.ConfigurableProduct:
            return (
                <LazyImg
                    src={firstConfigurableVariant?.product?.smallImage?.url}
                    alt={firstConfigurableVariant?.product?.smallImage?.label}
                />
            );
        default:
            return <LazyImg src={product.small_image.url} alt={product.small_image.label} />;
    }
}
