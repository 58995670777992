import {
    productOptions_productOptions_items_ConfigurableProduct,
    productOptions_productOptions_items_ConfigurableProduct_configurable_options_values_swatch_data_ImageSwatchData,
    productOptions_productOptions_items_ConfigurableProduct_variants_product_labels,
    productOptions_productOptions_items_ConfigurableProduct_variants_product_media_gallery,
} from 'src/queries/__generated__/productOptions';
import {
    cleanMediaEntries,
    DataSheet,
    productPrice,
    Specification,
} from 'src/components/ProductDetail/utils/product-detail.types';
import { MediaEntry, ProductPrice, ProductType } from 'src/types/global-types';
import { ProductAttrs, productAttrsFromQuery } from 'src/components/ProductDetail/utils/product-attrs.types';
import { SingleLabel } from 'src/components/ProductLabels/ProductLabel';
import { cleanLabelData } from 'src/components/ProductLabels/utils/ProductLabelData';

export type SelectedOptions =
    | Record<
          string,
          {
              value?: string;
              label?: string;
          }
      >
    | undefined;

export type ConfigurableOptions = CleanConfigurableOptions['configurable_options'];
export type ConfigurableVariants = CleanConfigurableOptions['variants'];

export type ConfigurableVariant = {
    product?: {
        id?: number;
        sku?: string;
        name?: string;
        attrs?: ProductAttrs;
        price?: ProductPrice;
        stock_status?: string;
        media_entries?: MediaEntry[];
        type: ProductType;
        delivery?: boolean;
        clickCollect?: boolean;
        description?: string;
        benefits?: string[];
        dataSheets?: DataSheet[];
        labels?: SingleLabel[];
        specifications?: Specification[];
        smallImage?: {
            label: string;
            url: string;
        };
    };
    attributes?: {
        code?: string;
        label?: string;
        value_index?: number;
        uid?: string;
    }[];
};

export type ConfigurableSwatchValue = {
    __typename: 'ImageSwatchData' | 'ColorSwatchData' | 'TextSwatchData';
    value?: string;
    thumbnail?: string;
};

export type ConfigurableOptionValue = {
    uid?: string;
    label?: string;
    swatch_data?: ConfigurableSwatchValue;
};

export type ConfigurableOption = {
    unavailableValues?: string[] | null;
    attribute_code?: string;
    label?: string;
    position?: number;
    values?: ConfigurableOptionValue[];
};

export type CleanConfigurableOptions = {
    configurable_options?: ConfigurableOption[];
    variants?: ConfigurableVariant[];
};

/**
 * Clean configurable options
 * This function is responsible for cleaning the configurable options into a more usable format
 * @param options
 */
export function cleanConfigurableProduct(
    options?: productOptions_productOptions_items_ConfigurableProduct,
): CleanConfigurableOptions | undefined {
    if (!options) return undefined;

    const { configurable_options, variants } = options;

    return {
        configurable_options: cleanConfigurableOptions(configurable_options, variants),
        variants: cleanConfigurableVariants(variants),
    };
}

/**
 * Clean configurable options
 * @param options
 * @param variants
 */
function cleanConfigurableOptions(
    options?: productOptions_productOptions_items_ConfigurableProduct['configurable_options'],
    variants?: productOptions_productOptions_items_ConfigurableProduct['variants'],
): ConfigurableOption[] | undefined {
    if (!options) return undefined;

    return options?.map((option) => {
        /**
         * Filter out the values that are not available in the variants
         * This is used to determine which values are not available to the user, so we can disable them on page load
         */
        const unavailableValues = option?.values
            ?.filter((value) => {
                return !variants?.some((variant) => {
                    return variant?.attributes?.some((attribute) => attribute?.uid === value?.uid);
                });
            })
            .map((value) => value?.uid)
            .filter((uid) => uid !== undefined) as string[];

        return {
            unavailableValues: unavailableValues.length > 0 ? unavailableValues : null,
            attribute_code: option?.attribute_code ?? undefined,
            label: option?.label ?? undefined,
            position: option?.position ?? undefined,
            values:
                option?.values?.map((value) => {
                    return {
                        uid: value?.uid ?? undefined,
                        label: value?.label ?? undefined,
                        swatch_data: value?.swatch_data
                            ? {
                                  __typename: value?.swatch_data.__typename,
                                  value: value?.swatch_data.value ?? undefined,
                                  thumbnail:
                                      (
                                          value?.swatch_data as productOptions_productOptions_items_ConfigurableProduct_configurable_options_values_swatch_data_ImageSwatchData
                                      ).thumbnail ?? undefined,
                              }
                            : undefined,
                    };
                }) ?? undefined,
        };
    });
}

/**
 * Clean configurable variants
 * @param variants
 */
function cleanConfigurableVariants(
    variants?: productOptions_productOptions_items_ConfigurableProduct['variants'],
): ConfigurableVariant[] | undefined {
    if (!variants) return undefined;

    return variants?.map((variant) => {
        const product = variant?.product;
        const attributes = variant?.attributes;

        const price = productPrice(
            product?.price_range.minimum_price?.regular_price?.value ?? 0,
            product?.price_range.maximum_price?.regular_price?.value ?? 0,
            product?.price_range.minimum_price?.final_price?.value,
            product?.price_range.maximum_price?.final_price?.value,
            product?.special_price,
            product?.woodies,
        );

        const media_entries = cleanMediaEntries(
            (variant?.product
                ?.media_gallery as productOptions_productOptions_items_ConfigurableProduct_variants_product_media_gallery[]) ||
                [],
        );

        const dataSheets = product?.product_datasheets as DataSheet[];
        const specifications = (product?.woodies?.specifications || []) as Specification[];
        const benefits = (product?.benefits || '').split(/\r?\n/g).filter(Boolean);
        const labels =
            product &&
            cleanLabelData<productOptions_productOptions_items_ConfigurableProduct_variants_product_labels>(
                product.labels,
            );

        return {
            product: {
                id: product?.id ?? undefined,
                sku: product?.sku ?? undefined,
                name: product?.name ?? undefined,
                attrs: product ? productAttrsFromQuery(product) : undefined,
                price: price ?? undefined,
                stock_status: product?.stock_status ?? undefined,
                media_entries: media_entries ?? undefined,
                type: (product?.__typename as ProductType) ?? undefined,
                description: product?.description?.html ?? undefined,
                benefits: benefits ?? undefined,
                dataSheets: dataSheets ?? undefined,
                labels: labels ?? undefined,
                specifications: specifications ?? undefined,
            },
            attributes:
                attributes?.map((attribute) => {
                    return {
                        code: attribute?.code ?? undefined,
                        label: attribute?.label ?? undefined,
                        value_index: attribute?.value_index ?? undefined,
                        uid: attribute?.uid ?? undefined,
                    };
                }) ?? undefined,
        };
    });
}
