import React from 'react';
import { Select as RxSelect } from '@wearejh/rx-form';
import { RxValidateFn } from '@wearejh/rx-form/lib/types';

import { ErrorFor } from './ErrorFor';

type SelectProps = {
    field: string;
    id?: string;
    initialValue?: string;
    label?: string;
    onChange?: (e: any) => void;
    options: { label: string; value: string; disabled?: boolean; selected?: boolean }[];
    required?: boolean;
    validate?: RxValidateFn;
};

export function Select(props: SelectProps) {
    const { label, id, field, validate, options, required } = props;
    return (
        <>
            <label htmlFor={id || field}>
                {label}
                {required && ' *'}
            </label>
            <RxSelect
                field={field}
                id={id || field}
                validate={validate}
                options={options}
                onChange={props.onChange}
                initialValue={props.initialValue}
            />
            <ErrorFor field={field} />
        </>
    );
}

Select.defaultProps = {
    required: false,
};
