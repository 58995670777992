import { imagePath } from 'src/util/formatUrl';
import { ProductPrice, ProductType } from 'src/types/global-types';
import { productPrice } from 'src/components/ProductDetail/utils/product-detail.types';
import { productName } from 'src/util/productName';
import { SingleLabel } from 'src/components/ProductLabels/ProductLabel';
import { cleanLabelData } from 'src/components/ProductLabels/utils/ProductLabelData';
import { ProductHitLabelData } from 'src/components/Algolia/utils/algolia.types';
import {
    CleanConfigurableOptions,
    cleanProductListConfigurableVariants,
} from 'src/components/ConfigurableOptions/utils';
import { cleanProductListConfigurableOptions } from 'src/components/ConfigurableOptions/utils/cleanProductListConfigurableOptions';

import {
    categoryProducts_categoryProducts_items,
    categoryProducts_categoryProducts_items_ConfigurableProduct,
    categoryProducts_categoryProducts_items_ConfigurableProduct_labels,
} from '../../../queries/__generated__/categoryProducts';

export interface ProductListItem {
    click_collect: boolean;
    configurableOptions?: CleanConfigurableOptions;
    delivery: boolean;
    energy_label_image?: string | null;
    energy_rating?: string | null;
    description?: string;
    id: number | string;
    labels: SingleLabel[] | ProductHitLabelData[];
    name: string;
    price: ProductPrice;
    product_fiche_image?: string | null;
    rating_value?: number;
    review_count?: number;
    sku?: string;
    small_image: {
        label: string;
        url: string;
    };
    type: ProductType;
    url: string;
}

export function productListItemsFromQuery(
    items: (categoryProducts_categoryProducts_items | null)[],
    options: { url_suffix: string },
): ProductListItem[] {
    return items.filter(Boolean).map((item) => {
        if (!item) throw new Error("trying to process a list item that doesn't exist");

        const minPrice = item.price_range.minimum_price?.regular_price?.value;
        const maxPrice = item.price_range.maximum_price?.regular_price?.value;

        const minSalePrice = item.price_range.minimum_price?.final_price?.value;
        const maxSalePrice = item.price_range.maximum_price?.final_price?.value;

        const discountValue = item.price_range.maximum_price?.discount?.amount_off;

        return {
            id: item.id!,
            name: productName(item.name || 'unknown'),
            url: '/' + item.url_key! + options.url_suffix,
            small_image: {
                label: item.small_image?.label || 'unknown',
                url: imagePath(item.small_image?.url),
            },
            price: productPrice(minPrice ?? 0, maxPrice ?? 0, minSalePrice, maxSalePrice, item.special_price, {
                price_per: item.woodies?.price_per ?? undefined,
                price_saving: discountValue !== 0 ? discountValue : undefined,
            }),
            click_collect: item.click_and_collected === 1,
            delivery: item.home_delivery === 1 || item.is_available_hd_delivery_centre === 1,
            labels: cleanLabelData<categoryProducts_categoryProducts_items_ConfigurableProduct_labels>(item.labels),
            energy_rating: item.woodies?.energy_rating ?? undefined,
            product_fiche_image: item.woodies?.product_fiche_image ?? undefined,
            configurableOptions:
                {
                    configurable_options: cleanProductListConfigurableOptions(
                        item as categoryProducts_categoryProducts_items_ConfigurableProduct,
                    ),
                    variants: cleanProductListConfigurableVariants(
                        item as categoryProducts_categoryProducts_items_ConfigurableProduct,
                    ),
                } ?? undefined,
            energy_label_image: item.woodies?.energy_label_image ?? undefined,
            type: item.__typename as ProductType,
        };
    });
}
